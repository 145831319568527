@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* bootstrap active input blue shadow remove */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
select:hover,
select:active,
select:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  appearance: none;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.form-control, .form-select {
  border: 1px solid #bebebe;
}

a {
  text-decoration: none;
}

.pointer:hover {
  cursor: pointer;
}

.cursor-text:hover {
  cursor: text !important;
}

html,
body {
  font-family: "Roboto";
}

html,
body {
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "GilroyLight";
  src: url("assets/fonts/Gilroy-Light.woff") format("woff");
}
.container {
  max-width: 1300px;
}

.fhcontent {
  background: #f6f6f6;
}

/* -------------------------------- color & font -------------------------------- */
/* color */
.btn-success {
  background-color: #08c152;
}

.btn-success:hover {
  background-color: #07a245;
}

.btn-failed {
  background-color: #ff0000;
}

.btn-failed:hover {
  background-color: #c50000;
}

.fhbgblue {
  background-color: #1d4593;
}

.fhbgblack {
  background-color: #171037;
}

.fhbgred {
  background-color: #c22525;
}

.fhbcyan {
  background-color: #18877d;
}

.fhbdark {
  background-color: #232323;
}

.fhbdark:hover,
.fhbdark:active,
.fhbdark:focus {
  background-color: #363333;
}

.fhbgblack:hover,
.fhbgblack:active,
.fhbgblack:focus {
  background-color: #241273;
}

.fhbcyan:hover,
.fhbcyan:active,
.fhbcyan:focus {
  background-color: #145977;
}

div .fhbcyan:hover,
div .fhbcyan:active,
div .fhbcyan:focus {
  background-color: #18877d;
}

.fhdisabledbtn {
  background-color: #7c7c7c;
  color: #3f3c3c;
}

.fhpublished {
  color: #08c152;
  font-weight: bold;
}

.fhbgred:hover,
.fhbgred:active,
.fhbgred:focus {
  background-color: #d30505;
}

.fhcblack {
  color: #171037 !important;
}

.txtdisabled,
.fhbgblue:disabled,
.fhbgblack:disabled,
.fhbgred:disabled,
.fhbcyan:disabled {
  color: #9c9c9c !important;
}

.fhbcyan.disabled {
  background: #18877d !important;
}

.fhbgblack:disabled,
.fhbgblack[disabled] {
  color: #363333 !important;
  background-color: #7c7c7c !important;
  font-weight: bold;
  opacity: 1;
}

.fhcyan {
  color: #0aa198 !important;
}

.fhbgcyan {
  background-color: #0aa198 !important;
}

.btn {
  border: 0px solid #000;
}

.btnlink {
  border: 2px solid #18877d !important;
  text-decoration: none !important;
}

.btnlinkmore {
  border: 2px solid #000 !important;
  text-decoration: none !important;
}

.mbtnmore .dropdown-toggle::after {
  display: none !important;
}

.mbtnbrdc1 {
  border: 2px solid #18877d;
}

.mbtnbrdc2 {
  border: 2px solid #000000;
}

.mbtnbrdc3 {
  border: 2px solid #1a475a;
}

.miconbold::before {
  -webkit-text-stroke: 1px !important;
}

.opacity-50 {
  opacity: 0.5;
}

.c1 {
  color: #18877d !important;
}

.bg1 {
  background-color: #18877d !important;
}

.border1 {
  border-color: #18877d !important;
}

.c2 {
  color: #606060 !important;
}

.bg2 {
  background-color: #3b3838 !important;
}

.c3 {
  color: #ffffff !important;
}

.bg3 {
  background-color: #ffffff !important;
}

.c4 {
  color: #d30505 !important;
}

.bg4 {
  background-color: #d30505 !important;
}

.bg5 {
  background-color: #f6f6f6;
}

.c6 {
  color: #232323;
}

.bg6 {
  background-color: #08192e !important;
}

.f1 {
  font-family: "Gilroy" !important;
}

.f1l {
  font-family: "GilroyLight" !important;
}

.text-success {
  color: #48b236 !important;
}

.fhbgblue {
  background-color: #0ca4d4 !important;
}

.fh1 {
  color: #18877d;
}

.fhc1 {
  color: #18877d;
}

.fhc1:hover,
.fhc1:active {
  color: #3BB4AD !important;
}

.fhc2 {
  color: #1a495b;
}

.fhc2:hover,
.fhc2:active {
  color: #1f5f7a !important;
}

.fhbg1 {
  background-color: #0aa198 !important;
}

.fhbg2 {
  background-color: #18877d !important;
}

.fhbtn1 {
  background-color: #0aa198 !important;
  border-radius: 8px;
}

.fhbtn1:hover,
.fhbtn1:active {
  background-color: #3BB4AD !important;
}

.fhbtn2 {
  background-color: #1a495b !important;
  border-radius: 8px;
}

.fhbtn2:hover,
.fhbtn2:active {
  background-color: #1f5f7a !important;
}

.fh3 {
  color: #1a475a;
}

.fhc3 {
  color: #1a475a;
}

.fhc3:hover,
.fhc3:active:active {
  color: #276782 !important;
}

.fhbg3 {
  background-color: #1a475a !important;
}

.fhbtn3 {
  background-color: #1a475a !important;
  border-radius: 8px;
}

.fhbtn3:hover,
.fhbtn3:active {
  background-color: #276782 !important;
}

.fh4 {
  color: #190d50;
}

.fhc4 {
  color: #190d50;
}

.fhc4:hover,
.fhc4:active:active {
  color: #372a6f !important;
}

.fhbg4 {
  background-color: #190d50 !important;
}

.fhbtn4 {
  background-color: #190d50 !important;
  border-radius: 8px;
}

.fhbtn4:hover,
.fhbtn4:active {
  background-color: #372a6f !important;
}

.fhbtn1t {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #0aa198 !important;
  box-shadow: 0px 0px 0px 2px #0aa198 inset !important;
  border-radius: 8px;
}

.fhbtn1t:hover,
.fhbtn1t:active {
  box-shadow: 0px 0px 0px 2px #3BB4AD inset !important;
  color: #3BB4AD !important;
}

.flagbtn {
  border: 1px solid #1a495b !important;
  border-radius: 5px !important;
  padding: 2px 6px !important;
}

.badgetag {
  color: #0aa198 !important;
  background: #d8f1eb !important;
}

.bgenable {
  background: #499f98 !important;
  color: #fff !important;
  font-weight: normal;
}

.bgdisable {
  border: 1px solid #0aa198 !important;
  color: #0aa198 !important;
  background: rgba(0, 0, 0, 0) !important;
  font-weight: normal;
}

.roundedmore {
  border-radius: 10px !important;
}

.fixwbtn {
  width: 151px !important;
}

.separator {
  border-right: 3px solid #18877d;
}

.separatorfh {
  border-right: 3px solid #18877d;
}

.backicon {
  background: #18877d;
  border-radius: 50%;
}

.fhgrad {
  background-image: linear-gradient(to right, #fff -5%, #dce9e8 100%);
}

.bggraddiv {
  position: relative !important;
}

.bggradlayer {
  /* Create the parallax scrolling effect */
  clip-path: polygon(0 0, 100% 0, 75% 64%, 0 100%, 0 100%, 0 30%);
  /* https://bennettfeely.com/clippy/ */
  background-image: linear-gradient(119deg, #83c19e 0%, #1a495b 69%);
  width: 100%;
  height: 105%;
  z-index: -9;
  top: 0;
  left: 0;
  position: absolute;
  max-height: calc(100vh);
}

.fullhght {
  /* Firefox */
  height: -moz-calc(100vh - 56px);
  /* WebKit */
  height: -webkit-calc(100vh - 56px);
  /* Opera */
  height: -o-calc(100vh - 56px);
  /* Standard */
  height: calc(100vh - 56px);
  /* 55px footer height +1 to make no scroll on side */
}

.divshadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.hvshadow:hover, .hvshadow:active, .hvshadow:focus {
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.15) !important;
}

#page-content-wrapper {
  background-color: #fff !important;
}

.disable-btn {
  color: #dadada !important;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.fhselect {
  border: 1px solid #18877d;
  color: #18877d;
  background-color: #f6f6f6;
  background-image: url("assets/images/icons/ic_Dropdown@3x.png");
  background-size: 11px;
}

.modal-content {
  border-radius: 3px !important;
  border: 0px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.modalIcon {
  max-width: 400px !important;
}

.help a {
  text-decoration: underline !important;
  color: #18877d !important;
}

.br {
  white-space: pre !important;
}

/* -------------------------------- logo & header -------------------------------- */
.fh-logo-div {
  max-width: 218px !important;
}

.fh-logo {
  max-width: 218px !important;
  width: 100%;
}

.fhrighticon {
  max-width: 25px;
}

.fhsearchicon {
  width: 19px;
  padding-bottom: 4px;
}

.fhsearchiconbtn {
  padding: 11px 21px;
  background-color: #0aa198 !important;
}

.fhsearchiconbtn:hover,
.fhsearchdropbtn:active,
.fhsearchdropbtn:visited,
.fhsearchdropbtn:link {
  background-color: #3BB4AD !important;
  border: 0px solid;
}

.fhsearchdropbtn,
.fhsearchdropbtn:hover,
.fhsearchdropbtn:active,
.fhsearchdropbtn:visited,
.fhsearchdropbtn:link {
  padding-left: 21px;
  padding-right: 21px;
  background-color: #174559 !important;
  border: 0px solid;
}

.fhusericon {
  width: 21px;
  border-radius: 4px;
}

.fhdashicon {
  width: 32px;
}

.searchinput {
  background-color: #fff;
  border-color: #f1f1f5;
}

.fhsearchdropbtn {
  border-radius: 8px !important;
}

.fhsearchiconbtn {
  background-color: #0aa198 !important;
}

.searchinput::placeholder {
  color: #92929d;
}

.searchinput:focus {
  border-color: #fff;
}

.fhbgmenu {
  background-color: none !important;
  color: #fff !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.fhnotif {
  font-size: 8px;
  position: fixed;
  margin: 0px -7px;
}

.divmenufh {
  padding-top: 20px;
  padding-bottom: 5px;
}

.selectlang {
  font-size: 14px;
}

.notifYellow {
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f0a331;
}

.bg-category {
  font-size: 16px;
}

.qrfqbtn {
  width: 159px;
  font-size: 14px;
}

.qrfqicon {
  width: 24px;
  margin: 0 2px;
}

.limitTxt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 575px) {
  .fh-logo-div {
    max-width: 148px !important;
  }

  .m-card-title h4 {
    font-size: 14px;
  }

  .bg-category {
    font-size: 10px;
  }

  .detailrfq .row * {
    margin-bottom: 8px;
  }

  .qrfqbtn {
    width: 100%;
    font-size: 12px;
  }

  .qrfqicon {
    width: 16px;
    margin: 0 2px;
  }

  .totalselected {
    padding: 5px 14px 6px 14px !important;
    border-radius: 6px;
    background-color: #eef5f6 !important;
    color: #08c152 !important;
  }
}
/* -------------------------------- footer -------------------------------- */
.fixed-bottom {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
}

#totop {
  display: inline-block;
  background-color: #232323;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 110px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  color: #fff;
  border: 1px solid #fff;
}

#totop::after {
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  color: #fff;
}

#totop:hover {
  cursor: pointer;
  background-color: #333;
}

#totop:active {
  background-color: #555;
}

#totop.show {
  opacity: 1;
  visibility: visible;
}

app-root:has(.fixed-bottom) .fhgradfooter {
  padding-bottom: 100px !important;
}

.divBack .bi-chevron-left::before {
  font-weight: bolder !important;
}

/* -------------------------------- breadcum -------------------------------- */
.divshbreadcum {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.shbreadcum {
  font-size: 12px;
}

.shbreadcum p {
  color: #232323 !important;
}

.breadcumsearchbox {
  border: 1px solid #18877d !important;
  background-color: #18877d !important;
}

.breadcumsearchinput {
  border: 1px solid #18877d !important;
}

.breadcumsearchdiv {
  padding-top: 2px;
}

/* -------------------------------- products -------------------------------- */
.divprodlist {
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.successNotif {
  color: #14c106 !important;
  background-color: #deffe7 !important;
  border-color: #14c106 !important;
}

.errorNotif {
  color: #ff5151 !important;
  background-color: #ffe7e7 !important;
  border-color: #ff5151 !important;
}

.infoNotif {
  color: #3c7bf5 !important;
  background-color: #eff1ff !important;
  border-color: #3c7bf5 !important;
}

.bgNotif {
  background-color: #3c7bf5 !important;
}

.bgtags {
  padding: 5px 10px !important;
}

.specDet {
  padding-left: 1rem;
  list-style-type: disc;
}

.iserror {
  font-size: 0.875em;
  color: #dc3545;
}

.thumbimgdiv1empty {
  background-image: url("/assets/images/default-image.jpg");
  background-size: auto 100% !important;
  background-position: center center;
  background-repeat: no-repeat;
  height: 196px;
  width: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.thumbimgdiv1empty h2 {
  display: none;
}

.thumbimgdiv2empty {
  background-image: url("/assets/images/default-image.jpg");
  background-size: auto 100% !important;
  background-position: center center;
  background-repeat: no-repeat;
  height: 60px;
  width: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.thumbimgdiv2empty p {
  display: none;
}

.productList {
  height: 132px !important;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
}

/* -------------------------------- rfq & quotation --------------------------------*/
.bg-unpublished {
  background-color: #4c7e94;
}

.bg-published {
  background-color: #18877d;
}

.bg-expired {
  background-color: #9f6816;
}

.bg-void {
  background-color: #b53a3a;
}

.participatingdiv {
  background-color: #08c152;
  color: #fff;
  font-size: 12px;
  padding: 6px 12px;
  margin: 0px 12px;
}

.ask-icon {
  max-width: 30px;
}

.ask-btn {
  border: solid 2px #0aa198;
  padding: 10px 16px;
  border-radius: 10px;
  color: #0aa198;
  cursor: pointer;
}

.ask-btn:hover {
  color: #0aa198;
}

.ask-btn-full {
  width: 100%;
  display: block;
  text-align: center;
}

.cart-icon {
  max-width: 30px;
}

.cart-btn {
  border: solid 2px #174559;
  padding: 10px 16px;
  border-radius: 10px;
  background: #174559;
  color: #fff;
  cursor: pointer;
}

.cart-btn:hover {
  background: #174559;
  color: #fff;
}

.cart-btn-full {
  width: 100%;
  display: block;
  text-align: center;
}

/* -------------------------------- timeline ------------------------------ */
.divtimeline {
  right: 0px;
  bottom: 75px;
  position: fixed;
}

.timelinecard {
  max-width: 329px;
  border-radius: 0px;
  border: 0px solid #000;
}

.timelinecard .card-header {
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
  padding: 12px 15px;
}

.bgtimelineheader {
  border-radius: 0px;
  background-color: #0aa198;
  color: #fff;
}

.texttimeline {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #000;
}

.timeline {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin: 5% auto;
  padding-left: 10%;
}
.timeline__event {
  width: 240px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  margin: 0px 0px 36px;
}
.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}
.timeline__event__date {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.timeline__event__icon {
  border-radius: 8px 0 0 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
  font-size: 2rem;
  color: #000;
}
.timeline__event__icon i {
  position: absolute;
  top: 50%;
  left: -65px;
  font-size: 2.5rem;
  transform: translateY(-50%);
}
.timeline__event__description {
  flex-basis: 60%;
}
.timeline__event:after {
  content: "";
  width: 2px;
  height: 170%;
  background: #c9c9c9;
  position: absolute;
  top: 52%;
  right: 3.9rem;
  z-index: 1;
}
.timeline__event:before {
  content: "";
  width: 2rem;
  height: 2rem;
  position: absolute;
  background: url("/assets/images/icons/unchecked-timeline.png") center center no-repeat;
  border-radius: 170%;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  border: 0px solid #9251ac;
  z-index: 20;
}
.timeline__event--type2:before {
  background: url("/assets/images/icons/unchecked-timeline.png") center center no-repeat;
  border-color: #555ac0;
}
.timeline__event--type2:after {
  background: #c9c9c9;
}
.timeline__event--type2 .timeline__event__date {
  color: #000;
}
.timeline__event--type2 .timeline__event__icon {
  background: #fff;
  color: #555ac0;
}
.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}
.timeline__event--type3:before {
  background: url("/assets/images/icons/checked-timeline.png") center center no-repeat;
  border-color: #24b47e;
}
.timeline__event--type3:after {
  background: #24b47e;
}
.timeline__event--type3 .timeline__event__date {
  color: #000;
}
.timeline__event--type3 .timeline__event__icon {
  background: #fff;
  color: #24b47e;
}
.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}
.timeline__event:last-child:after {
  content: none;
}

.inactiveTimeline {
  color: #8c8c8c !important;
}

.activeTimeline {
  color: #0aa198 !important;
}

.inactiveIcon:before {
  background: url("/assets/images/icons/unchecked-timeline.png") center center no-repeat;
  background-size: 18px;
}

.activeIcon:before {
  background: url("/assets/images/icons/checked-timeline.png") center center no-repeat;
  background-size: 28px;
}

.activeLine:after {
  background: #c9c9c9;
}

.inactiveLine:after {
  background: #c9c9c9;
}

.timelinecard .fhbcyan {
  background-color: #0aa198 !important;
}

.timelinecard .fhbgblack {
  background-color: #1a475a !important;
}

/* -------------------------------- assets -------------------------------- */
.helpsuccess .modal-content {
  border-radius: 20px !important;
}

.fharrowCircle {
  max-width: 35px;
}

.borderinside, .borderinside:hover, .borderinside:active, .borderinside:link {
  -webkit-box-shadow: inset 0px 0px 0px 1px #18877d !important;
  -moz-box-shadow: inset 0px 0px 0px 1px #18877d !important;
  box-shadow: inset 0px 0px 0px 1px #18877d !important;
  color: #18877d !important;
}

.apprrejBtn {
  padding: 8px 15px;
  width: 100%;
  max-width: 90px;
  border-radius: 10px;
}

.apprrejBtn {
  padding: 8px 15px;
  width: 100%;
  max-width: 90px;
  border-radius: 10px;
}

.outlineicon {
  width: 100%;
  max-width: 18px;
  padding-top: 3px;
}

.catpill {
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  text-transform: none;
  font-weight: normal;
  cursor: pointer;
}

@media (max-width: 768px) {
  .divmenufh {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fhsearchiconbtn {
    padding: 8px;
  }
}
@media (max-width: 575px) {
  .dashicons_menu {
    display: none !important;
  }

  .fhsearchiconbtn {
    padding: 6px 10px;
    background-color: #0aa198 !important;
  }

  .fhsearchiconbtn:hover,
.fhsearchdropbtn:active,
.fhsearchdropbtn:visited,
.fhsearchdropbtn:link {
    background-color: #3BB4AD !important;
    border: 0px solid;
  }

  body {
    font-size: 12px;
  }

  .btn {
    font-size: 12px;
  }

  .participatingdiv {
    padding: 3px 6px;
    margin: 0px 6px;
  }

  .outlineicon {
    max-width: 15px;
    padding-bottom: 2px !important;
  }

  .divPaging {
    font-size: 12px;
  }

  .divPaging .form-select {
    font-size: 12px;
  }

  .divPaging .fharrowCircle {
    max-width: 26px;
    margin-top: 2px;
  }

  .ask-icon {
    max-width: 20px;
  }

  .ask-btn {
    padding: 6px 9px;
  }

  .productList {
    height: 112px !important;
  }

  .divprodlist h4 {
    font-size: 14px;
  }

  .catpill {
    font-size: 12px;
    padding: 8px 15px;
    margin: 3px;
  }
}
@font-face {
  font-family: "lg";
  src: url("../fonts/lg.woff2?io9a6k") format("woff2"), url("../fonts/lg.ttf?io9a6k") format("truetype"), url("../fonts/lg.woff?io9a6k") format("woff"), url("../fonts/lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1084;
  outline: none;
  border: none;
}
.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}
.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}
.lg-single-item .lg-next,
.lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}
.lg-next:before {
  content: "";
}

.lg-prev {
  left: 20px;
}
.lg-prev:after {
  content: "";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}
.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close:after {
  content: "";
}
.lg-toolbar .lg-maximize {
  font-size: 22px;
}
.lg-toolbar .lg-maximize:after {
  content: "";
}
.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
.lg-sub-html a {
  color: inherit;
}
.lg-sub-html a:hover {
  text-decoration: underline;
}
.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  -o-transform: scale3d(0.5, 0.5, 0.5);
  -ms-transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}
.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}
.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}
.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-zoom-from-image {
  opacity: 1;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap;
}
.lg-outer .lg-item {
  display: none !important;
}
.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}
.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}
.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}
.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}
.lg-container.lg-show {
  display: block;
}
.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}
.lg-inline .lg-backdrop {
  z-index: 1;
}
.lg-inline .lg-outer {
  z-index: 2;
}
.lg-inline .lg-maximize:after {
  content: "";
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out 0s;
  -moz-transition: -moz-transform 0.35s ease-out 0s;
  -o-transition: -o-transform 0.35s ease-out 0s;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-backdrop.in {
  opacity: 0.8;
}